import React, { useState } from 'react';
import { getPartnersId } from '../../../services/app-service';
import { AppContext } from '../../../global/context/app-context';
import {
  NotificationLevel,
  REACT_QUERY_RETRY_PREDICATE,
  REACT_QUERY_STALE_TIME,
  ReactQueryKeys,
} from '../../../constants';
import ApiInventoryStatsHelper from './api-inventory-stats-helper';
import { useQuery } from '@tanstack/react-query';
import { showNotification } from '../../../services/notification-service';
import ReportService from '../../../services/report-service';
import { TranslationKey } from '../../../helpers/utils';

interface GlobalInventoryMetric {
  count: number;
  downloadReport?: VoidFunction;
  label: string;
  tooltip?: TranslationKey;
}

interface UseGlobalInventoryResult {
  data: {
    metrics: GlobalInventoryMetric[];
    lastUpdate: string | null;
  };
  isLoading: boolean;
  error: Error | null;
  refetch: VoidFunction;
  isDownloadingReport: boolean;
}

export function useGlobalInventory(): UseGlobalInventoryResult {
  const { partner, subPartners } = React.useContext(AppContext);
  const partnerIds = getPartnersId(partner, subPartners);

  const {
    data: viewData,
    isLoading: isLoadingView,
    isError: isViewError,
    refetch: refetchView,
  } = {
    data: [],
    isLoading: false,
    isError: false,
    refetch: () => {},
  };

  const {
    data: statsData,
    isLoading: isLoadingStats,
    isError: isStatsError,
    refetch: refetchStats,
  } = useQuery({
    queryKey: [ReactQueryKeys.INVENTORY_STATS, partnerIds.join()],
    queryFn: () => ApiInventoryStatsHelper.getInventoryStats(partnerIds),
    staleTime: REACT_QUERY_STALE_TIME,
    retry: REACT_QUERY_RETRY_PREDICATE,
  });

  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  async function downloadReport(downloadReportFn: () => Promise<void>) {
    try {
      setIsDownloadingReport(true);
      await downloadReportFn();
    } catch (e) {
      showNotification({
        message: (e as any).message,
        level: NotificationLevel.ERROR,
      });
    } finally {
      setIsDownloadingReport(false);
    }
  }

  return {
    data: {
      metrics: [
        {
          label: 'Active matched',
          tooltip: 'analytics_tooltip_active_matched',
          count: sum(viewData, 'active_matched'),
          downloadReport: () =>
            downloadReport(() =>
              ReportService.triggerInventoryByType('active_matched', partnerIds),
            ),
        },
        {
          label: 'Failed matches',
          count: sum(statsData || [], 'numFailedMatches'),
          tooltip: 'dashboard_global_inventory_mismatched_items_tooltip',
        },
        {
          label: 'Duplicate matches',
          tooltip: 'dashboard_global_inventory_duplicate_items_tooltip',
          count: sum(statsData || [], 'numDuplicatedMatches'),
        },
        {
          label: 'Active unmatched',
          tooltip: 'analytics_tooltip_active_unmatched',
          count: sum(viewData, 'active_unmatched'),
          downloadReport: () =>
            downloadReport(() =>
              ReportService.triggerInventoryByType('active_unmatched', partnerIds),
            ),
        },
      ],
      lastUpdate: lastUpdatedAt(statsData),
    },
    isLoading: isLoadingView || isLoadingStats,
    error: isViewError || isStatsError ? new Error('Unable to load global inventory') : null,
    refetch() {
      refetchView();
      refetchStats();
    },
    isDownloadingReport,
  };
}

function sum(data: any[], key: string): number {
  let total = 0;
  for (const el of data) {
    const value = el[key];
    if (!value) continue;
    total += value;
  }
  return total;
}

function lastUpdatedAt(statsData: any) {
  if (!statsData || statsData.length === 0) return null;
  let max = statsData[0].updatedAt;
  for (const el of statsData) {
    if (el.updatedAt > max) max = el.updatedAt;
  }
  return max;
}
