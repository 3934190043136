// TOTAL ROW - OVERWRITE NAME (TI-640)
export const COLUMN_KEY_I2P_ID = 'id';
export const COLUMN_KEY_NAME2 = 'name2';
export const COLUMN_KEY_OPPORTUNITY_CPC = 'opportunity_cpc';
export const COLUMN_KEY_LOCAL_CODE = 'pos2';
export const COLUMN_KEY_STATUS = 'status';
export const COLUMN_KEY_PARTNER_REFERENCE = 'pid';
export const COLUMN_KEY_URL = 'url';
export const COLUMN_KEY_DATE = 'p_v_a_date';
export const COLUMN_KEY_RAS = 'ras';
export const COLUMN_KEY_BIDDING_TYPE_TIME_PERIOD = 'time_bidding_type';
export const COLUMN_KEY_BIDDING_TYPE_HOTEL_HIDDEN = 'hidden_bidding_type';
export const COLUMN_KEY_ANOMALIES = 'discrepancies';
export const COLUMN_KEY_PROPERTIES_ALL = 'campaigns_properties';
export const COLUMN_KEY_PROPERTIES_INACTIVE = 'inactive_properties';
export const COLUMN_KEY_PROPERTIES_INVISIBLE = 'invisible_properties';
export const COLUMN_KEY_PROPERTIES_NO_BID = 'campaigns_properties_without_bids';
export const COLUMN_VALUE_STATUS_PENDING = 'pending';
export const COLUMN_KEY_CPA_GROUP = 'cpa_group';

// EXPRESS BOOKING SUGGESTIONS
export const COLUMN_POS = 'pos';

// INVENTORY WIDGET
export const INVENTORY_COLUMN_NAME_FOR_UNMATCHED = 'matching_status_hotel';
export const INVENTORY_COLUMN_NAME_FOR_CONDITION_MATCHED = 'status';
export const COLUMN_NAME_ITEM_2_PARTNER = 'item2partner';
// DIMENSIONS
export const COLUMN_NAME_REGION = 'region';
export const COLUMN_NAME_CITY = 'city_dimensions';
export const COLUMN_NAME_COUNTRY = 'country';
// DIMENSIONS
export const COLUMN_MODIFIER_VALUE_LOS = 'current_modifier';
export const COLUMN_MODIFIER_VALUE_TTT = 'current_modifier';
export const COLUMN_MODIFIER_VALUE_DD = 'current_modifier';
export const COLUMN_MODIFIER_VALUE_GS = 'current_modifier';
export const COLUMN_MODIFIER_PENDING_STATUS = 'status_bm';
export const COLUMN_MODIFIER_PENDING_VALUE = 'Pending';
export const COLUMN_BREAKOUT_LOS = 'bm_los_breakout_view';
export const COLUMN_BREAKOUT_TTT = 'bm_ttt_breakout_view';
export const COLUMN_BREAKOUT_GS = 'bm_gs_breakout_view';
export const COLUMN_BREAKOUT_DD = 'default_date';
export const COLUMN_BREAKOUT_LOS_ID = 'bm_los_breakout_id';
export const COLUMN_BREAKOUT_TTT_ID = 'bm_ttt_breakout_id';
export const COLUMN_BREAKOUT_GS_ID = 'bm_gs_breakout_id';
export const COLUMN_BREAKOUT_DD_ID = 'default_date_id';

export const COLUMN_BEAT = 'beat_share2';
export const COLUMN_MEET = 'meet_share2';
export const COLUMN_LOSE = 'lose_share2';
export const COLUMN_UNAVAILABILITY = 'unavailability_share2';
export const COLUMN_PROPERTY_GROUP_ID = 'property_group_ids';

// CAMPAIGNS
export const COLUMN_CAMPAIGNS_ID = 'campaigns_id';
export const COLUMN_CAMPAIGNS_LOCALE_CODE = 'campaigns_locale';
export const COLUMN_CAMPAIGNS_TYPE = 'campaigns_type';
export const COLUMN_CAMPAIGNS_CPA_VALUE = 'campaigns_cpa_value';
export const COLUMN_CAMPAIGNS_PROPERTIES = 'campaigns_properties';
export const COLUMN_CAMPAIGNS_PROPERTIES_WITHOUT_BID = 'campaigns_properties_without_bids';
export const COLUMN_CAMPAIGNS_MIN_CPA_VALUE = 'campaigns_min_cpa_value';
export const COLUMN_CAMPAIGNS_VALUE_STATUS_PENDING = 'no change';

// SPONSORED LISTINGS - ANALYTICS
export const COLUMN_KEY_PARTNER_REFERENCE_SL = 'sl_hv_partner_ref';
export const COLUMN_KEY_NAME_SL = 'sl_hv_name';
export const COLUMN_KEY_LOCAL_CODE_SL = 'sl_pos_pos';
export const COLUMN_KEY_URL_SL = 'sl_hv_url';

// SPONSORED LISTINGS - CAMPAIGNS
export const COLUMN_SPONSORED_CAMPAIGNS_ID = 'sl_c_pos';
export const COLUMN_SPONSORED_CAMPAIGNS_LOCALE_CODE = 'sl_c_pos';
export const COLUMN_SPONSORED_POS = 'pos';
