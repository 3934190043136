import { FileUploaderOption } from '../../../components/button-dropdown-file-uploader';
import {
  BID_MODIFIER_SAMPLE_URL,
  BID_SAMPLE_URL,
  CAMPAIGN_ALLOCATION_SAMPLE_URL,
  CPA_BID_MODIFIER_SAMPLE_URL,
  CPA_SAMPLE_URL,
  INVENTORY_SAMPLE_URL,
  PROPERTY_GROUP_SAMPLE_URL,
} from '../../../constants';
import { BidFileType } from '../../../models';

const MB = 1024 * 1024;

export class BaseBidUploaderOption implements FileUploaderOption {
  remainingPushes: number;
  isDisabled: boolean;
  disabledMessage?: string;

  constructor(remainingPushes: number) {
    this.remainingPushes = remainingPushes;
    this.isDisabled = this.remainingPushes === 0;
    this.disabledMessage = disabledMessage(this.remainingPushes === 0);
  }

  type = BidFileType.BASE_BID;
  sampleFileUrl = BID_SAMPLE_URL;
  text = 'analytics_home_upload_base_bids';
  errorValidateLocales = 'upload_bids_file_error_invalid_locales';
  maxFileSize = 150 * MB;
}

export class BidModifierUploaderOption implements FileUploaderOption {
  remainingPushes: number;
  disabledMessage?: string;
  isDisabled: boolean;

  constructor(remainingPushes: number) {
    this.remainingPushes = remainingPushes;
    this.isDisabled = this.remainingPushes === 0;
    this.disabledMessage = disabledMessage(this.remainingPushes === 0);
  }

  type = BidFileType.BID_MODIFIER;
  sampleFileUrl = BID_MODIFIER_SAMPLE_URL;
  text = 'analytics_home_upload_bid_modifiers';
  errorValidateLocales = 'upload_bid_modifiers_file_error_invalid_locales';
  maxFileSize = 40 * MB;
}

export class CpaBidModifierUploaderOption implements FileUploaderOption {
  disabledMessage?: string;
  isDisabled: boolean;

  constructor(
    private remainingPushes: number,
    private isCpaEligible: boolean,
  ) {
    this.isDisabled = !this.isCpaEligible || this.remainingPushes === 0;
    this.disabledMessage = disabledMessage(this.remainingPushes === 0);
  }

  type = BidFileType.CPA_BID_MODIFIER;
  sampleFileUrl = CPA_BID_MODIFIER_SAMPLE_URL;
  text = 'analytics_home_upload_cpa_bid_modifiers';
  errorValidateLocales = 'upload_bid_modifiers_file_error_invalid_locales';
  maxFileSize = 40 * MB;
}

export class PropertyGroupsUploaderOption implements FileUploaderOption {
  remainingPushes: number;
  isDisabled: boolean;
  disabledMessage?: string;

  constructor(remainingPushes: number) {
    this.remainingPushes = remainingPushes;
    this.isDisabled = this.remainingPushes === 0;
    this.disabledMessage = disabledMessage(this.remainingPushes === 0);
  }

  type = BidFileType.PROPERTY_GROUPS;
  sampleFileUrl = PROPERTY_GROUP_SAMPLE_URL;
  text = 'analytics_home_upload_property_groups';
  errorValidateLocales = 'upload_property_groups_file_error_invalid_locales';
  maxFileSize = 40 * MB;
}

export class CampaignAllocationUploaderOption implements FileUploaderOption {
  isDisabled: boolean;
  disabledMessage?: string;
  constructor(
    private remainingPushes: number,
    private isCpaEligible: boolean,
  ) {
    this.isDisabled = !this.isCpaEligible || this.remainingPushes === 0;
    this.disabledMessage = disabledMessage(this.remainingPushes === 0);
  }

  type = BidFileType.CAMPAIGN_ALLOCATION;
  sampleFileUrl = CAMPAIGN_ALLOCATION_SAMPLE_URL;
  text = 'upload_bids_campaign_allocation';
  errorValidateLocales = 'upload_campaign_allocation_file_error_invalid_locales';
  maxFileSize = 40 * MB;
}

export class CpaUploaderOption implements FileUploaderOption {
  remainingPushes: number;
  isDisabled: boolean;
  disabledMessage: string | undefined;

  constructor(remainingPushes: number) {
    this.remainingPushes = remainingPushes;
    this.isDisabled = this.remainingPushes === 0;
    this.disabledMessage = disabledMessage(this.remainingPushes === 0);
  }

  type = BidFileType.CPA;
  sampleFileUrl = CPA_SAMPLE_URL;
  text = 'upload_bids_cpa_values';
  errorValidateLocales = 'upload_cpa_file_error_invalid_locales';
  maxFileSize = 40 * MB;
}

function disabledMessage(usedAllPushes: boolean) {
  if (usedAllPushes) return 'No pushes available today';
  return undefined;
}
export class InventoryUploaderOption implements FileUploaderOption {
  type = BidFileType.INVENTORY;
  sampleFileUrl = INVENTORY_SAMPLE_URL;
  text = 'upload_bids_inventory_file';
  errorValidateLocales = 'inventory_file_error_invalid_locales';
  maxFileSize = 500 * MB;
  remainingPushes = 0;
  isDisabled = false;
}
