import React, { useState } from 'react';
import { Props } from './sponsored-general-information-panel.types';
import './sponsored-general-information-panel.css';
import { BaseNotification, BiddingActionType } from '../../../../../models';
import { Loading } from '../../../../../components/loading';
import { Notification } from '../../../../../components/notification';
import { SponsoredHotelDetailInformation } from '../sponsored-hotel-detail-information';
import { Redirect } from 'react-router';
import {
  SL_BID_CAP,
  SL_DETAILED_HOTEL_VIEW_PREFIX,
  SPONSORED_ANALYTICS_ROUTE,
  TIMEOUT_NOTIFICATION,
} from '../../../../../constants';
import { HotelControlPanel } from '../../../../../components/hotel-control-panel';

export const SponsoredGeneralInformationPanel: React.FunctionComponent<Props> = ({
  hotel,
  isLoading,
  onUpdate,
}) => {
  const [notification, setNotification] = useState<BaseNotification | undefined>(undefined);
  const [isRedirectActive, setRedirectActive] = useState<boolean>(false);

  const onShowNotification = (notificationTmp: BaseNotification) => {
    setNotification(notificationTmp);
    setTimeout(onCloseNotification, TIMEOUT_NOTIFICATION);
  };
  const onCloseNotification = () => setNotification(undefined);
  const activateRedirect = () => setRedirectActive(true);

  return (
    <>
      {isRedirectActive && <Redirect to={SPONSORED_ANALYTICS_ROUTE} />}
      {isLoading ? (
        <div className="u-background--blue-very-light">
          <Loading isWhite={true} className="u-padding--gutter" />
        </div>
      ) : (
        <section>
          <HotelControlPanel
            hotels={hotel !== undefined ? [hotel] : []}
            onUpdateTable={onUpdate}
            onShowNotification={onShowNotification}
            onClickLeading={activateRedirect}
            isShowingDetail={true}
            trackingPrefix={SL_DETAILED_HOTEL_VIEW_PREFIX}
            biddingActionType={BiddingActionType.SlBid}
            maxCPCBidCap={SL_BID_CAP}
          />
          {hotel !== undefined && <SponsoredHotelDetailInformation hotel={hotel} />}
          {notification && (
            <Notification notification={notification} onClose={onCloseNotification} />
          )}
        </section>
      )}
    </>
  );
};
