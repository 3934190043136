import { FC, useState, useEffect, useContext } from 'react';
import { SelectorBarProps } from './selector-bar.types';
import './selector-bar.css';
import {
  areArraysEqual,
  getDifferenceBetweenDays,
  translate,
  translateHtml,
} from '../../../helpers/utils';
import { VerticalDivider } from '../../../components/vertical-divider';
import { ComposedDropdown } from '../../../components/composed-dropdown';
import { AppContext } from '../../context/app-context';
import { areMultipleBrandsSelected, getPartner } from '../../../services/app-service';
import {
  ANALYTICS_ROUTE,
  BRANDS_ALLSUBBRANDS,
  BRANDS_SUBGROUP,
  DATE_FORMAT_LONG_COMA,
  DATE_RANGE_COMPARISON_ONBOARDING_KEY,
  POINT_OF_SALE,
  POS_GLOBAL,
  POS_GLOBAL_VALUE,
  REGIONS,
  HOTEL_DETAILS_ROUTE,
} from '../../../constants';
import {
  DatePeriod,
  Option,
  Partner,
  Selection as SelectorBarOptions,
  SelectorBarOption,
} from '../../../models';
import { format } from 'date-fns';
import { DatePicker } from '../../../components/date-picker/date-picker';
import { DatePickerWithCompare } from '../../../components/date-picker/date-picker-with-compare';
import { globalOption } from './default-options';
import { AreaPos } from './area-pos';
import { AreaBrand } from './area-brand';
import { savePeriodToLocalStorage } from '../../../helpers/local-storage-helper';
import { getSelectedBrands } from '../../../services/analytics-service';
import {
  useCompareDateRangePeriods,
  useDateRangePeriod,
  useIsCompareEnabled,
  useIsComparing,
} from '../../../components/date-picker/date-picker.state';
import { useLocation } from 'react-router-dom';
import { OnboardingTooltip } from '../../../components/onboarding-tooltip';
import { matchPath } from 'react-router';
import { PopoverDirection } from '../../../components/popover';
import { DateSelector } from './date-selector';

export const SelectorBar: FC<SelectorBarProps> = ({
  regions,
  isBrandMultiSelectionAvailable,
  isPosSelectionAvailable,
  isDateSelectionAvailable,
}) => {
  const [selectorBarOptionSelected, setSelectorBarOptionSelected] = useState(
    SelectorBarOptions.NONE,
  );
  const [globalCompareDateRangePeriods] = useCompareDateRangePeriods();
  const [isCompareEnabled, setIsCompareEnabled] = useIsCompareEnabled();
  const [isComparing, setIsComparing] = useIsComparing();
  const location = useLocation();

  const shouldUseDatePickerWithCompare =
    !!matchPath(location.pathname, { path: ANALYTICS_ROUTE }) ||
    !!matchPath(location.pathname, { path: HOTEL_DETAILS_ROUTE });

  useEffect(() => {
    if (!shouldUseDatePickerWithCompare) setIsComparing(false);
    setIsCompareEnabled(shouldUseDatePickerWithCompare);
  }, [location]);

  useEffect(() => {
    if (!shouldUseDatePickerWithCompare) setIsComparing(false);
  }, [shouldUseDatePickerWithCompare]);

  const [brand, setBrand] = useState<SelectorBarOption>({
    options: [],
    optionsSelected: [],
    previousSavedOptionSelected: [],
    subMenuSelected: BRANDS_SUBGROUP,
    search: '',
  });

  const [pos, setPos] = useState<SelectorBarOption>({
    options: [],
    optionsSelected: [],
    previousSavedOptionSelected: [],
    subMenuSelected: POINT_OF_SALE,
    search: '',
  });

  const {
    partner,
    subPartners,
    locales,
    selectedLocales,
    onUpdatePeriod,
    onUpdateLocales,
    onUpdateSubpartners,
  } = useContext(AppContext);

  const [globalDateRangePeriod, setGlobalDateRangePeriod] = useDateRangePeriod();
  const { from, to, period } = globalDateRangePeriod;

  useEffect(() => {
    if (isPosSelectionAvailable) {
      setPos({ ...pos, optionsSelected: pos.previousSavedOptionSelected });
    } else {
      setPos({ ...pos, optionsSelected: [globalOption] });
    }
    if (selectorBarOptionSelected === SelectorBarOptions.POS) {
      setSelectorBarOptionSelected(SelectorBarOptions.NONE);
    }
  }, [isPosSelectionAvailable]);

  useEffect(() => {
    const options = [
      globalOption,
      ...locales.map(locale => ({
        label: locale.name,
        value: locale.localeCode,
        group: POINT_OF_SALE,
        flag: locale.localeCode,
        id: locale.localeCode,
      })),
      ...regions.map(region => ({
        label: region.regionName,
        value: region.localeCodes.join(', '),
        group: REGIONS,
        subLabel: `${translate('analytics_home_region_aggregation')} ${
          region.localeCodes.length
        } ${translate('analytics_home_region_aggregation_1')}`,
        flag: POS_GLOBAL,
        id: region.localeCodes.join(', '),
      })),
    ];

    let previousSavedOptionSelected: Option[];
    if (selectedLocales[0] === POS_GLOBAL_VALUE) {
      previousSavedOptionSelected = [globalOption];
    } else {
      previousSavedOptionSelected = selectedLocales.map(locale => {
        return options.find(({ id }) => locale === id)!;
      });
    }

    const areEqual = areArraysEqual(
      Array.from(selectedLocales),
      pos.optionsSelected
        .map(({ id }) => id!.split(','))
        .flat()
        .map(localeTmp => localeTmp.trim()),
      (t1, t2) => t1 === t2,
    );

    if (!areEqual || pos.optionsSelected.length === 0) {
      setPos({
        ...pos,
        options,
        previousSavedOptionSelected,
        optionsSelected: previousSavedOptionSelected,
      });
    }
  }, [locales, regions, selectedLocales]);

  useEffect(() => {
    let previousSavedOptionSelected: Option[];

    const selectedBrands: Option[] = getSelectedBrands(partner, Array.from(subPartners));

    const options: Option[] = partner.subpartners.map(partnerToOption);

    const areAllBrandsSelected = options.length === selectedBrands.length;

    if (areAllBrandsSelected) {
      previousSavedOptionSelected = [buildAllPartnerOption()];
    } else {
      previousSavedOptionSelected = selectedBrands;
    }

    setBrand({
      ...brand,
      previousSavedOptionSelected,
      options: [buildAllPartnerOption(), ...options],
      optionsSelected: previousSavedOptionSelected,
    });
  }, [partner, subPartners]);

  useEffect(() => {
    const selectedBrands = optionsToPartners(brand.optionsSelected);
    const areMultipleOptionsSelected = selectedBrands.length > 1;
    if (areMultipleOptionsSelected && !isBrandMultiSelectionAvailable) {
      const firstSelectedBrand = selectedBrands[0];
      setBrand({
        ...brand,
        optionsSelected: [partnerToOption(firstSelectedBrand)],
        previousSavedOptionSelected: [partnerToOption(firstSelectedBrand)],
      });
      onUpdateSubpartners([firstSelectedBrand]);
    }
  }, [isBrandMultiSelectionAvailable]);

  const buildAllPartnerOption = () => ({
    label: `${translate('analytics_home_subbrands_all')} ${partner.name}`,
    value: BRANDS_ALLSUBBRANDS,
    group: BRANDS_SUBGROUP,
    id: BRANDS_ALLSUBBRANDS,
  });

  const brandSubtitle = (): string => {
    const effectivePartner = getPartner(partner, subPartners);
    if (!areMultipleBrandsSelected(subPartners)) {
      return effectivePartner.name;
    }
    if (partner.subpartners.length === subPartners.length) {
      return `${translate('analytics_home_subbrands_all')} ${partner.name}`;
    }
    return `${subPartners.length} ${translate('analytics_home_subbrand_selector_selected')}`;
  };

  const [posSubtitle, posIcon] = ((): [string, string] => {
    const { previousSavedOptionSelected } = pos;

    if (previousSavedOptionSelected.length !== 1) {
      return [
        `${previousSavedOptionSelected.length} ${translate(
          'analytics_home_pos_selector_selected',
        )}`,
        POS_GLOBAL,
      ];
    } else if (previousSavedOptionSelected[0].value !== POS_GLOBAL_VALUE) {
      const localeOption = pos.options.find(
        ({ value }) => value === previousSavedOptionSelected[0].value,
      );
      return !localeOption ? ['', ''] : [localeOption.label, localeOption.flag!];
    }
    return [translate(POS_GLOBAL), POS_GLOBAL];
  })();

  const calculateDateRangeSubtitle = () => {
    const f = format(from, DATE_FORMAT_LONG_COMA);
    const t = format(to, DATE_FORMAT_LONG_COMA);
    let subtitle = `${translate(period)} (${f} - ${t})`;
    if (isComparing && isCompareEnabled && globalCompareDateRangePeriods.length) {
      subtitle = `${translate(
        'selector_bar_compare',
        String(globalCompareDateRangePeriods.length + 1),
      )}`;
    }

    if (period === DatePeriod.CUSTOM) {
      const daysBetween = Math.abs(getDifferenceBetweenDays(from, to));
      subtitle = `${translate('selector_bar_period', (daysBetween + 1).toString(), `${f} - ${t}`)}`;
    }

    if (period === DatePeriod.YESTERDAY) {
      subtitle = `${translate(period)} (${f})`;
    }
    return subtitle;
  };

  const clickOpenClose = (selection: SelectorBarOptions) => {
    if (selection === selectorBarOptionSelected) {
      cleanPosAndBrandSelection();
      setSelectorBarOptionSelected(SelectorBarOptions.NONE);
    } else {
      setSelectorBarOptionSelected(selection);
      if (selection === SelectorBarOptions.CALENDAR) {
        cleanPosAndBrandSelection();
      }
    }
  };

  const cleanPosAndBrandSelection = () => {
    setBrand({
      ...brand,
      optionsSelected: brand.previousSavedOptionSelected,
    });
    setPos({
      ...pos,
      optionsSelected: pos.previousSavedOptionSelected,
    });
  };

  const partnerToOption = (partnerTmp: Partner): Option => ({
    label: partnerTmp.name,
    value: partnerTmp.partnerId.toString(),
    group: BRANDS_SUBGROUP,
    id: partnerTmp.partnerId.toString(),
  });

  const optionsToPartners = (selectedBrandOptions: Option[]): Partner[] => {
    if (selectedBrandOptions[0]?.value === BRANDS_ALLSUBBRANDS) return partner.subpartners;

    const partnersId = new Set<number>(selectedBrandOptions.map(({ id }) => +id!));
    return partner.subpartners.filter(partnerTmp => partnersId.has(partnerTmp.partnerId));
  };

  const cancel = () => {
    setSelectorBarOptionSelected(SelectorBarOptions.NONE);
    setBrand({
      ...brand,
      optionsSelected: brand.previousSavedOptionSelected,
    });
    setPos({
      ...pos,
      optionsSelected: pos.previousSavedOptionSelected,
    });
  };

  const saveSelection = () => {
    saveLocales();
    saveBrands();
  };

  const saveLocales = () => {
    const nextLocales: string[] = pos.optionsSelected
      .map(({ id }) => id!.split(','))
      .flat()
      .map(localeTmp => localeTmp.trim());

    onUpdateLocales(nextLocales);

    setPos({
      ...pos,
      previousSavedOptionSelected: pos.optionsSelected,
    });

    setSelectorBarOptionSelected(SelectorBarOptions.NONE);
  };

  const saveBrands = () => {
    onUpdateSubpartners(optionsToPartners(brand.optionsSelected));

    setBrand({
      ...brand,
      previousSavedOptionSelected: brand.optionsSelected,
    });

    setSelectorBarOptionSelected(SelectorBarOptions.NONE);
  };

  const renderGlobalFilterPicker = () => {
    switch (selectorBarOptionSelected) {
      case SelectorBarOptions.POS:
        if (!isPosSelectionAvailable) return;
        return <AreaPos pos={pos} onUpdatePos={setPos} onCancel={cancel} onSave={saveSelection} />;
      case SelectorBarOptions.BRAND:
        return (
          <AreaBrand
            brand={brand}
            onUpdateBrand={setBrand}
            partnerOption={buildAllPartnerOption()}
            isBrandSelectionMultiple={isBrandMultiSelectionAvailable}
            onCancel={cancel}
            onSave={saveSelection}
          />
        );
      case SelectorBarOptions.CALENDAR:
        if (!isDateSelectionAvailable) return;
        return datePicker();
      default:
        return;
    }
  };

  const renderDatePickerOnboardingTooltip = () => {
    if (!isDateSelectionAvailable) return;
    return (
      <div style={{ position: 'relative' }}>
        {onboardingTooltip(shouldUseDatePickerWithCompare)}
      </div>
    );
  };

  const posSelector = () => {
    if (!isPosSelectionAvailable) return;
    return (
      <ComposedDropdown
        title={translate('analytics_home_pos_selector')}
        subTitle={posSubtitle}
        onClick={() => clickOpenClose(SelectorBarOptions.POS)}
        isSelected={selectorBarOptionSelected === SelectorBarOptions.POS}
        icon={posIcon}
        qaId="selector-bar-pos"
      />
    );
  };

  const datePicker = () => {
    if (shouldUseDatePickerWithCompare)
      return (
        <DatePickerWithCompare
          offset="left"
          onClose={() => setSelectorBarOptionSelected(SelectorBarOptions.NONE)}
          isPeriodChoosable={true}
          isComparingPreselected={isComparing}
        />
      );
    return (
      <DatePicker
        offset="left"
        onClose={() => setSelectorBarOptionSelected(SelectorBarOptions.NONE)}
        isPeriodChoosable={true}
        onUpdateRange={(fromTmp, toTmp, periodValueTmp) => {
          const dateRangePeriod = {
            from: fromTmp,
            to: toTmp,
            period: periodValueTmp,
          };
          onUpdatePeriod(dateRangePeriod);
          savePeriodToLocalStorage(dateRangePeriod);
          setSelectorBarOptionSelected(SelectorBarOptions.NONE);
          setGlobalDateRangePeriod(dateRangePeriod);
        }}
        from={from}
        to={to}
        period={period}
      />
    );
  };

  const brandSelector = (
    <ComposedDropdown
      title={translate('analytics_home_subbrand_selector')}
      subTitle={brandSubtitle()}
      onClick={() => clickOpenClose(SelectorBarOptions.BRAND)}
      isSelected={selectorBarOptionSelected === SelectorBarOptions.BRAND}
      icon={''}
      isReadOnlyMode={partner.subpartners.length === 0}
      qaId="selector-bar-brand"
    />
  );

  const title = (
    <p className="c-selectorBar__label u-display--flex u-align-items--center u-padding-right--medium u-color--juri-lighter">
      {translate('analytics_home_data_selector')}
    </p>
  );

  return (
    <section className="c-selector-bar">
      <article
        className="u-display--flex u-align-items--center u-border--juri-very-light u-border-bottom u-padding--gutter"
        data-qa="selector-bar"
      >
        {title}
        <VerticalDivider />
        {brandSelector}
        <VerticalDivider />
        {posSelector()}
        <VerticalDivider />
        <DateSelector
          isDateSelectionAvailable={isDateSelectionAvailable}
          calculateDateRangeSubtitle={() => <span>{calculateDateRangeSubtitle()}</span>}
          selectorBarOptionSelected={selectorBarOptionSelected}
          clickOpenClose={clickOpenClose}
        />
        <VerticalDivider />
        <div className="u-margin-left--auto u-position--relative"></div>
      </article>
      {renderGlobalFilterPicker()}
      {renderDatePickerOnboardingTooltip()}
    </section>
  );
};

const onboardingTooltip = (comparingDates: boolean) => {
  if (!comparingDates) return;
  return (
    <div style={{ position: 'absolute', bottom: 4, left: 500 }}>
      <OnboardingTooltip
        keyStorage={DATE_RANGE_COMPARISON_ONBOARDING_KEY}
        direction={PopoverDirection.BOTTOM_LEADING}
      >
        <p>
          {translateHtml('analytics_date_compare_onboarding_tooltip')} <br />
          {translate('analytics_date_compare_trend_onboarding_tooltip')}
        </p>
      </OnboardingTooltip>
    </div>
  );
};
