import { useQuery } from '@tanstack/react-query';
import {
  useCompareDateRangePeriods,
  useIsComparing,
} from '../../../components/date-picker/date-picker.state';
import { COLUMN_KEY_CPA_GROUP, COLUMN_KEY_DATE } from '../../../constants';
import { BaseViewPayloadV2 } from '../../../helpers/api/analytics/types';
import {
  generateCompositeConditionForAllDateRanges,
  useViewTable,
  UseViewTableRtn,
} from '../../../hooks/use-view-table';
import { Aggregation, View } from '../../../models';
import { useContext } from 'react';
import { AppContext } from '../../../global/context/app-context';
import { getPartnersId } from '../../../services/app-service';
import ApiAnalyticsHelper from '../../../helpers/api/analytics/api-analytics-helper';
import { useAggregations } from '../../../hooks/use-aggregations/use-aggregations';
import { isNumber } from 'lodash';
import { useFilters } from '../../../global/context/filter-context';

export const useTimeView = (
  aggregation: Aggregation,
  payload: BaseViewPayloadV2,
): UseViewTableRtn => {
  const [isComparing] = useIsComparing();
  const view = View.TIME;

  const result = useViewTable(view, aggregation, payload, {
    columnId: COLUMN_KEY_DATE,
    biddingTypeId: COLUMN_KEY_CPA_GROUP,
  });

  const total = useTimeViewTotal(result, isComparing, payload, view, aggregation);

  let table = { ...result.table, total: total.total };
  if (total.total?.length === 0)
    table = { ...result.table, total: [], data: { rows: [], count: 0 } };

  return {
    ...result,
    table,
    isLoading: result.isLoading || total.isLoading,
  };
};

const useTimeViewTotal = (
  result: UseViewTableRtn,
  isComparing: boolean,
  payload: BaseViewPayloadV2,
  view: View,
  aggregation: Aggregation,
) => {
  const { from, to } = payload;
  const { partner, subPartners } = useContext(AppContext);
  const partnersId = getPartnersId(partner, subPartners);
  const [compareDatePeriods] = useCompareDateRangePeriods();

  const aggregations = useAggregations(aggregation);
  const { filters } = useFilters();

  const fetchTotalForComparing = async () => {
    try {
      const condition = generateCompositeConditionForAllDateRanges(
        [{ from, to }, ...compareDatePeriods],
        result.table.columns,
      );

      const data = await ApiAnalyticsHelper.fetchRowsByView(view, aggregations, {
        locales: payload.locales,
        pagination: result?.pagination.pagination,
        partnersId,
        filters: result?.filters.allActiveFilters.concat(condition ?? [], filters),
      });

      const totalRow = data[0];

      Object.keys(totalRow).map(key =>
        !isNumber(totalRow[key]) ? (totalRow[key] = '-') : totalRow[key],
      );

      return [{ ...totalRow, p_v_a_date: 'Total' }];
    } catch (error) {
      return [];
    }
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['time-view-total', result.table.data, filters],
    queryFn: fetchTotalForComparing,
    enabled: isComparing && result.table.columns.length > 0,
  });

  if (error) return { total: result.table.total, isLoading };

  if (!isComparing) return { total: result.table.total, isLoading: false };

  return { total: data, isLoading };
};
