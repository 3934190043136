import { FC } from 'react';
import { SegmentedButtons } from '../../core-ui/components/segmented-buttons/segmented-buttons';
import { Tooltip } from '../../core-ui/components/tooltip/tooltip';
import { translate } from '../../helpers/utils';
import trackerService from '../../services/tracker/tracker-service';
import {
  AbsoluteOrRelative,
  useAbsoluteOrRelativeComparison,
} from './use-absolute-or-relative-comparison';
import styles from './absolute-relative-toggle.module.css';

export const AbsoluteRelativeToggle: FC<{ viewName: string }> = ({ viewName }) => {
  const { activeTrend, setActiveTrend, disabled } = useAbsoluteOrRelativeComparison();

  function onChange(newValue: AbsoluteOrRelative) {
    setActiveTrend(newValue);
    trackerService.track(`${viewName} view, ${newValue} change clicked`);
  }

  const segmentedButtons = (
    <SegmentedButtons
      disabled={disabled}
      value={disabled ? null : activeTrend}
      onChange={onChange}
      options={[
        {
          value: 'relative',
          icon: { name: 'percentage' },
          tooltip: disabled ? undefined : { title: 'Relative', position: 'topCenter' },
        },
        {
          value: 'absolute',
          icon: { name: 'hash' },
          tooltip: disabled ? undefined : { title: 'Absolute', position: 'topCenter' },
        },
      ]}
    />
  );

  if (disabled) {
    return (
      <Tooltip
        title={translate('absolute_relative_toggle_disabled_message')}
        position="topCenter"
        className={styles.disabledTooltip}
      >
        {segmentedButtons}
      </Tooltip>
    );
  }

  return segmentedButtons;
};
