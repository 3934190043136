import { FC } from 'react';
import styles from './average-data-toggle.module.css';
import { Toggle } from '../../components/toggle';
import { translate } from '../../helpers/utils';
import TrackerService from '../../services/tracker/tracker-service';
import { Tooltip } from '../../core-ui/components/tooltip/tooltip';
import { ANALYTICS_VIEW_AVERAGE_DATA_TOGGLE } from '../../constants';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';

export const AverageDataToggle: FC<{
  value: boolean;
  setValue: (newValue: boolean) => void;
  tooltip: string;
}> = ({ value, setValue, tooltip }) => {
  const currentView = String(useCurrentView()).toLowerCase();

  const handleChange = (nextValue: boolean) => {
    //TODO: Add triggered_by: yesterday overview when it's integrated with YO
    const isToggleOn = nextValue ? 'on' : 'off';
    TrackerService.track(`${currentView} ${ANALYTICS_VIEW_AVERAGE_DATA_TOGGLE}`, {
      toggle: isToggleOn,
    });
    setValue(nextValue);
  };

  return (
    <div className={styles.wrapper}>
      <Tooltip title={tooltip} position="topCenter">
        <Toggle
          isActive={value}
          label={translate('average_data_label')}
          onChange={handleChange}
          style={{
            border: 'none',
            padding: 0,
            gap: 'var(--abs-8)',
          }}
        />
      </Tooltip>
    </div>
  );
};
