import ReactDOM from 'react-dom/client';
import { unregister } from './register-service-worker';
import './styles/index.css';
import { Login } from './views/login';
import './wdyr';

require('promise.prototype.finally').shim();

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<Login />);
}

unregister();
