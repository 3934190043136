import { FileUploaderOption } from '../../../components/button-dropdown-file-uploader';
import { BidFileType } from '../../../models';
import {
  SL_BID_MODIFIER_SAMPLE_URL,
  SL_BID_SAMPLE_URL,
  SL_BUDGET_CAP_SAMPLE_URL,
} from '../../../constants';

const MB = 1024 * 1024;

export class SlBaseBidUploaderOption implements FileUploaderOption {
  isDisabled: boolean;
  disabledMessage?: string;
  constructor(private remainingPushes: number) {
    this.isDisabled = this.remainingPushes === 0;
    this.disabledMessage = disabledMessage(this.remainingPushes === 0);
  }

  type = BidFileType.SL_BASE_BID;
  sampleFileUrl = SL_BID_SAMPLE_URL;
  text = 'sponsored_campaigns__upload_base_bid';
  errorValidateLocales = 'sponsored_campaigns__upload_base_bid_file_error_invalid_locales';
  maxFileSize = 40 * MB;

}

export class SlBidModifierUploaderOption implements FileUploaderOption {
  isDisabled: boolean;
  disabledMessage?: string;
  constructor(private remainingPushes: number) {
    this.isDisabled = this.remainingPushes === 0;
    this.disabledMessage = disabledMessage(this.remainingPushes === 0);
  }

  type = BidFileType.SL_BID_MODIFIER;
  sampleFileUrl = SL_BID_MODIFIER_SAMPLE_URL;
  text = 'sponsored_campaigns__upload_bid_modifier';
  errorValidateLocales = 'sponsored_campaigns__upload_bid_modifier_file_error_invalid_locales';
  maxFileSize = 40 * MB;
}

export class SlBudgetCapUploaderOption implements FileUploaderOption {
  isDisabled: boolean;
  disabledMessage?: string;
  constructor(private remainingPushes: number) {
    this.isDisabled = this.remainingPushes === 0;
    this.disabledMessage = disabledMessage(this.remainingPushes === 0);
  }

  type = BidFileType.SL_BUDGET_CAP;
  sampleFileUrl = SL_BUDGET_CAP_SAMPLE_URL;
  text = 'sponsored_campaigns__upload_budget_cap';
  errorValidateLocales = 'sponsored_campaigns__upload_budget_cap_file_error_invalid_locales';
  maxFileSize = 40 * MB;
}

function disabledMessage(usedAllPushes: boolean) {
  if (usedAllPushes) return 'No pushes available today';
  return undefined;
}
