import { FC, useContext } from 'react';
import {
  useCompareDateRangePeriods,
  useDateRangePeriod,
  useIsComparing,
} from '../../../components/date-picker/date-picker.state';
import { Grid, Row, SortDirection } from '../../../components/grid';
import { getComparedRows } from '../../../components/grid/utils/grid-utils';
import { ResultsNotFound } from '../../../components/results-not-found';
import { ShownMetricsSelector } from '../../../components/shown-metrics-selector';
import { Toolbar } from '../../../components/toolbar';
import { SearchTextFilter } from '../../../components/toolbar/search-text-filter';
import { ExportOption, ViewExporter } from '../../../components/view-exporter';
import {
  COLUMN_KEY_ANOMALIES,
  COLUMN_KEY_CPA_GROUP,
  COLUMN_KEY_LOCAL_CODE,
  FEATURE_FLAGS,
  GRID_VIEW_CONFIGURATION,
  NotificationLevel,
  VIEW_NAME,
} from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { formatColumnsAndRows } from '../../../helpers/grid-formatter';
import { getNoOfCalendarDays, translate } from '../../../helpers/utils';
import { useNestedRows } from '../../../hooks/use-nested-rows/use-nested-rows';
import { useShownMetricsSelector } from '../../../hooks/use-shown-metrics-selector';
import { useViewTable } from '../../../hooks/use-view-table';
import { Aggregation, DateRangePeriod, FileFormat, Partner, View } from '../../../models';
import { getPartnersId } from '../../../services/app-service';
import { showNotification } from '../../../services/notification-service';
import ReportService from '../../../services/report-service';
import styles from '../common-view-styles.module.css';
import { AbsoluteRelativeToggle } from '../absolute-relative-toggle';
import { useAbsoluteOrRelativeComparison } from '../use-absolute-or-relative-comparison';
import { useTrackRowExpanded } from '../use-track-row-expanded';
import { withSortTracking } from '../with-sort-tracking';
import { useAverageData } from '../use-average-data';
import { AverageDataToggle } from '../average-data-toggle';
import { getAverageRows, getAverageRowsForNestedRows } from '../analytics-utils';

type Props = {
  partner: Partner;
  subPartners: Partner[];
  locales: string[];
  from: Date;
  to: Date;
};

export const PosView: FC<Props> = () => {
  const [isComparing] = useIsComparing();
  const { partner, subPartners, selectedLocales } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();

  const view = View.POS;
  const aggregation = Aggregation.POS;
  const columnId = COLUMN_KEY_LOCAL_CODE;
  const numOfStickyColumns = 1;
  const { activeTrend } = useAbsoluteOrRelativeComparison();
  const { isAverageDataActive, setAverageDataActive } = useAverageData();
  const [globalCompareDateRangePeriods] = useCompareDateRangePeriods();

  const requestPayload = {
    locales: selectedLocales,
    from,
    to,
  };

  const {
    table: { columns, data, total },
    isLoading: isLoadingParentRows,
    retry,
    setSearch,
    pagination,
    filters: { allActiveFilters, hasGlobalFilters, clearGlobalFilters },
    sort: { sort, setSort },
  } = useViewTable(view, aggregation, requestPayload, {
    columnId,
    biddingTypeId: COLUMN_KEY_CPA_GROUP,
    combineDatePeriods: true,
  });

  const exportRasReport = async (row: Row) => {
    const locales = row[columnId] ? [row[columnId]] : [];
    try {
      return await ReportService.export(View.RAS_REPORT, [aggregation], FileFormat.CSV, {
        partnersId: getPartnersId(partner, Array.from(subPartners)),
        locales: locales.length !== 0 ? locales : selectedLocales,
        from,
        to,
        filters: [],
      });
    } catch (e) {
      showNotification({
        message: (e as any).message,
        level: NotificationLevel.ERROR,
      });
    }
  };

  const localeColumnIds = [columnId, 'locale_code'];
  const nestingColumnId =
    columns.find(column => localeColumnIds.includes(column.key))?.key ?? columnId;

  const { nestedRows: rows, isLoading: isLoadingNestedRows } = useNestedRows({
    view,
    aggregation,
    payload: requestPayload,
    filters: allActiveFilters,
    parentRows: data.rows,
    columnId: nestingColumnId,
  });

  const isLoading = isLoadingParentRows || isLoadingNestedRows;

  let dataRows = rows;

  if (isAverageDataActive) {
    dataRows = getAverageRows(dataRows, getNoOfCalendarDays(from, to));
  }

  if (isComparing && isAverageDataActive) {
    const allDateRanges: Omit<DateRangePeriod, 'period'>[] = [
      { from, to },
      ...globalCompareDateRangePeriods,
    ];

    dataRows = dataRows.map(row => ({
      ...row,
      nested: getAverageRowsForNestedRows(row.nested, allDateRanges),
    }));
  }

  if (isComparing && dataRows[0]?.nested?.length === 2) {
    dataRows = dataRows.map(row => ({
      ...row,
      nested: getComparedRows(row.nested, columns, activeTrend),
    }));
  }

  const { formattedColumns, formattedRows, formattedFooterRows } = formatColumnsAndRows({
    columns,
    rows: dataRows,
    total,
    id: columnId,
    customColumns: {
      columnCellClickActions: {
        [COLUMN_KEY_ANOMALIES]: { action: exportRasReport },
      },
    },
  });

  const { shownMetrics, setShownMetrics, availableMetrics, visibleFormattedColumns } =
    useShownMetricsSelector(
      view,
      numOfStickyColumns,
      columns,
      formattedColumns,
      isAverageDataActive,
    );

  const viewExporter = () => (
    <ViewExporter
      key="key-view-exporter"
      title={translate('analytics_home_export_report')}
      exportOptions={getExportOptions()}
      view={view}
      columns={columns}
      aggregation={aggregation}
      filters={allActiveFilters}
      sort={sort}
    />
  );

  const trackRowExpanded = useTrackRowExpanded('POS');

  const filters = [
    <SearchTextFilter
      key="key-custom-filters"
      placeholder={translate('analytics_home_search_item_pos')}
      onSearch={filterTmp => setSearch(filterTmp !== undefined ? [filterTmp] : [])}
      searchableColumnNames={localeColumnIds}
    />,
  ];

  if (isComparing)
    filters.push(<AbsoluteRelativeToggle key="absolute-relative-toggle" viewName="POS" />);

  if (FEATURE_FLAGS.enableQuickDataExploration)
    filters.push(
      <AverageDataToggle
        key="average-data-toggle"
        value={isAverageDataActive}
        setValue={setAverageDataActive}
        tooltip={translate('average_data_tooltip')}
      />,
    );

  return (
    <div className={styles.toolbarWrapper}>
      <Toolbar
        filters={filters}
        actions={[
          <ShownMetricsSelector
            key="key-metrics-selector"
            options={availableMetrics}
            selectedByDefaultOptions={shownMetrics}
            view={view}
            onApply={setShownMetrics}
          />,
          viewExporter(),
        ]}
      />
      <div className={styles.gridWrapper}>
        <Grid
          numStickyColumns={numOfStickyColumns}
          columns={visibleFormattedColumns}
          rows={formattedRows}
          footerRows={formattedFooterRows}
          isLoading={isLoading}
          configuration={GRID_VIEW_CONFIGURATION}
          onSort={withSortTracking((columnName: string, direction: SortDirection) => {
            setSort({ columnName, direction });
          }, VIEW_NAME[view])}
          pagination={{
            page: pagination.pagination,
            totalItems: data.count,
            onPreviousPage: pagination.setPrevPage,
            onNextPage: pagination.setNextPage,
            onUpdateRowsPerPage: pagination.setNumItemsPerPage,
          }}
          resultsNotFound={
            //TODO: Avoid to show the button before isloading for some seconds
            <ResultsNotFound
              isButtonEnabled={hasGlobalFilters && !isLoading}
              onClick={clearGlobalFilters}
              retry={retry}
            />
          }
          isAccordionTable={isComparing}
          onExpandRow={isExpanded => {
            if (isExpanded) trackRowExpanded();
          }}
        />
      </div>
    </div>
  );
};

const getExportOptions = () => {
  const options: ExportOption[] = [
    {
      text: translate('analytics_toolbar_export_performance_grid'),
      fileFormat: FileFormat.CSV,
    },
    {
      text: translate('analytics_toolbar_export_performance_grid_excel'),
      fileFormat: FileFormat.EXCEL,
    },
  ];
  return options;
};
