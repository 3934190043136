import { createContext, useContext } from 'react';

type AverageDataProps = {
  isAverageDataActive: boolean;
  setAverageDataActive: (value: boolean) => void;
};

export const useAverageData = () => {
  const context = useContext(AverageDataContext);

  if (!context) {
    throw new Error('useAverageData must be used within a useAverageDataProvider');
  }

  return { ...context };
};

export const AverageDataContext = createContext<AverageDataProps | null>(null);
