import cn from 'classnames';
import { FC, useState } from 'react';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import { KEYBOARD_EVENT_ENTER } from '../../../constants';
import { useCurrentView } from '../../../hooks/use-current-view/use-current-view';
import { FilterColumn, FilterTypeCondition } from '../../../models';
import trackerService from '../../../services/tracker/tracker-service';
import { InputIcon } from '../../input-icon';
import './search-text-filter.css';
import { SearchTextFilterProps } from './search-text-filter.types';
import { Button } from '../../../core-ui/components/button/button';

const BaseSearchTextFilter: FC<SearchTextFilterProps> = ({
  placeholder,
  onSearch,
  searchableColumnNames,
  className = '',
}) => {
  const [search, setSearch] = useState('');

  const applyFilters = (searchValue: string) => {
    if (searchValue === '') {
      onSearch(undefined, searchValue);
      return;
    }

    const searchValues = searchValue.split(',').map(searchValueTmp => searchValueTmp.trim());
    const condition: FilterColumn = {
      columnName: '',
      columnLabel: '',
      type: FilterTypeCondition.OR,
      value: '',
      condition: searchableColumnNames.flatMap(columnNameTmp => {
        return searchValues.map(searchValueTmp => {
          return {
            columnName: columnNameTmp,
            columnLabel: '',
            type: FilterTypeCondition.CONTAINS,
            value: searchValueTmp,
          };
        });
      }),
    };

    onSearch(condition, searchValue);
  };

  const cleanSearch = () => {
    const cleanedSearch = '';
    setSearch(cleanedSearch);
    applyFilters(cleanedSearch);
  };

  return (
    <div className="u-display--flex">
      <InputIcon
        icon={search.length === 0 ? undefined : CloseIcon}
        className={cn('c-input c-input--wider c-input--attached-right', className)}
        placeholder={placeholder}
        value={search}
        onChange={event => setSearch(event.target.value)}
        onKeyPress={event => {
          if (event.key === KEYBOARD_EVENT_ENTER) {
            applyFilters(search);
          }
        }}
        onClickIcon={cleanSearch}
        qaId="filters-search"
      />
      <Button
        variant="tertiary"
        onClick={() => applyFilters(search)}
        data-qa="filters-search-button"
        icon={{ name: 'search' }}
      />
    </div>
  );
};

export const SearchTextFilter: FC<SearchTextFilterProps> = ({ onSearch, ...props }) => {
  const currentView = useCurrentView();
  const onSearchWithTracking = (filter?: FilterColumn, searchValue?: string) => {
    trackerService.track(`Search Keyword in ${currentView} view`, {
      searchKeyword: searchValue,
    });
    onSearch(filter, searchValue);
  };

  return <BaseSearchTextFilter {...props} onSearch={onSearchWithTracking} />;
};
