import {
  addDays,
  addYears,
  differenceInCalendarDays,
  differenceInDays,
  format,
  subDays,
  subYears,
} from 'date-fns';
import { DATE_FORMAT_STANDARD } from '../../constants';
import { Period } from './format-date';
import { DateRange } from '../../models';

export const getLastXDays = (days: number, isTodayChoosable?: boolean) => {
  return subDays(new Date(), isTodayChoosable ? days - 1 : days);
};

export const getDifferenceBetweenDays = (from: Date, to: Date) => {
  return differenceInDays(to, from);
};

export function isDateBetween(date: string | Date, from: Date, to: Date): boolean {
  const time = new Date(date).getTime();
  return time >= from.getTime() && time <= to.getTime();
}

export function nextHourUTC(date: Date) {
  const hours = date.getUTCHours() + 1;
  const hh = hours > 9 ? hours : `0${hours}`;

  if (hh === 24) return '00:00';

  return `${hh}:00`;
}

export const dateFromYmd = (ymd: string): Date =>
  new Date(
    parseInt(ymd.substring(0, 4)),
    parseInt(ymd.substring(4, 6)) - 1,
    parseInt(ymd.substring(6, 8)),
  );

export function shiftForwards(date: Date, period: Period, dateRange?: DateRange): Date {
  switch (period) {
    case Period.PREVIOUS:
      return addDays(date, differenceInCalendarDays(dateRange!.to, dateRange!.from) + 1);
    case Period.YEAR_OVER_YEAR:
      return addYears(date, 1);
    case Period.YEAR_OVER_2_YEARS:
      return addYears(date, 2);
  }
}

export function shiftYmdForwards(ymd: number, period: Period, dateRange?: DateRange): number {
  return Number(
    format(shiftForwards(dateFromYmd(String(ymd)), period, dateRange), DATE_FORMAT_STANDARD),
  );
}

export function shiftBackwards(date: Date, period: Period, dateRange?: DateRange): Date {
  switch (period) {
    case Period.PREVIOUS:
      return subDays(date, differenceInCalendarDays(dateRange!.to, dateRange!.from) + 1);
    case Period.YEAR_OVER_YEAR:
      return subYears(date, 1);
    case Period.YEAR_OVER_2_YEARS:
      return subYears(date, 2);
  }
}

export const getNoOfCalendarDays = (from: Date, to: Date) => differenceInCalendarDays(to, from) + 1;
