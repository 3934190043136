import React from 'react';
import { TimeOption } from '../../constants';
import { AppContext } from '../../global/context/app-context';
import { useWidgetData } from '../use-widget-data';
import {
  Aggregation,
  FilterColumn,
  SelectedDimensionApi,
  Sort,
  SortDirection,
  View,
  WidgetType,
} from '../../models';
import { getPartnersId } from '../../services/app-service';
import {
  Dimension,
  DIMENSION_AGGREGATION,
  DIMENSION_VIEW,
  isDestinationDimension,
} from '../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';
import {
  CHART_DATA_AGGREGATION_TIME_OPTION,
  CHART_DATA_DATE_COLUMN_TIME_OPTION,
} from '../../views/custom-dashboard/widget';
import { useDateRangePeriod } from '../../components/date-picker/date-picker.state';
import { parseDestination } from '../../views/custom-dashboard/destination-dimension-menu';

function getTimelineView(widgetType: WidgetType, dimension: Dimension | undefined) {
  if (widgetType === WidgetType.CHART_BUILDER) {
    return dimension ? DIMENSION_VIEW[dimension] : View.TIMELINE_WITH_SL;
  }
  return View.TIMELINE_WITH_SL;
}

const createCondition = (
  dimensionName: Dimension,
  aggregationColumn: string,
  dimensionValues: string[],
): FilterColumn => {
  if (isDestinationDimension(dimensionName)) {
    return {
      columnName: '',
      columnLabel: '',
      type: 'OR',
      value: '',
      condition: dimensionValues.map(it => destinationCondition(it)),
    };
  }
  return {
    columnName: aggregationColumn,
    columnLabel: '',
    type: 'IN',
    value: dimensionValues.join(','),
  };
};

function destinationCondition(value: string): FilterColumn {
  const { region, city } = parseDestination(value);
  const columnName = city ? 'city' : region ? 'region' : 'country';
  return {
    columnLabel: '',
    columnName,
    type: 'EQUALS',
    value,
  };
}

export function useTimelineData(
  timeOption: TimeOption,
  metricKeys: string[],
  dimension?: SelectedDimensionApi,
  widgetType = WidgetType.TIMELINE,
  dependencies: any[] = [],
) {
  const { selectedLocales, partner, subPartners } = React.useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();
  const dateColumnName = CHART_DATA_DATE_COLUMN_TIME_OPTION[timeOption];
  const aggregations: Aggregation[] = [CHART_DATA_AGGREGATION_TIME_OPTION[timeOption]];
  const condition: FilterColumn[] = [];
  const sort: Sort[] = [];
  const dimensionValues = dimension?.values;
  const shownColumns = metricKeys.concat(dateColumnName);
  if (dimensionValues) {
    const dimensionInfo = DIMENSION_AGGREGATION[dimension!!.name];
    sort.push({
      columnName: dateColumnName,
      direction: SortDirection.ASC,
    });
    if (dimensionInfo.columnName !== 'timePeriod') {
      shownColumns.push(dimensionInfo.columnName);
      aggregations.push(dimensionInfo.aggregation);
      const aggregationColumn = dimensionInfo.columnName;
      condition.push(createCondition(dimension!.name, aggregationColumn, dimensionValues));
      sort.push({
        columnName: aggregationColumn,
        direction: SortDirection.ASC,
      });
    }
  }
  return useWidgetData(
    getTimelineView(widgetType, dimension?.name),
    {
      aggregations,
      condition,
      partnersId: getPartnersId(partner, subPartners),
      localeCodes: [...selectedLocales],
      from,
      to,
      sort,
      shownColumns,
      timeOption,
      dimension: dimension?.name,
      dimensionValues,
      metricKeys,
    },
    [selectedLocales, subPartners, from, to, timeOption, ...dependencies],
  );
}
