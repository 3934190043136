import { Props } from './metric-trend.types';
import './metric-trend.css';
import { calculatePercentage, compareTo, translate } from '../../../../helpers/utils';
import cs from 'classnames';
import { TooltipPosition, TooltipWrapper } from '../../../../components/tooltip-wrapper';
import iconPointingArrow from './icn-trend-arrow.svg';
import iconPointingArrowGreen from './icn-trend-arrow-green.svg';
import iconPointingArrowRed from './icn-trend-arrow-red.svg';
import { formatNumber, percentage } from '../../../../helpers/formater';

export const MetricTrend: React.FunctionComponent<Props> = ({
  wrapper,
  metric: { yesterday, last7days },
}) => {
  const getCorrectArrowIcon = (
    yesterdayParam: number | string,
    last7DaysParam: number | string | undefined,
  ) => {
    if (typeof yesterdayParam === 'number' && typeof last7DaysParam === 'number') {
      const comparison = compareTo(yesterdayParam, last7DaysParam);
      if (1 === comparison) {
        return iconPointingArrowGreen;
      } else if (-1 === comparison) {
        return iconPointingArrowRed;
      } else {
        return iconPointingArrow;
      }
    } else {
      return iconPointingArrow;
    }
  };

  const compareYesterdayOverLast7Days = (
    yesterdayParam: number | string,
    last7DaysParam: number | string | undefined,
  ) => {
    if (typeof yesterdayParam === 'number' && typeof last7DaysParam === 'number') {
      return compareTo(yesterdayParam, last7DaysParam);
    } else {
      return 0;
    }
  };

  const percentageYesterdayOverLast7Days = (
    yesterdayParam: number | string,
    last7DaysParam: number | string | undefined,
  ) => {
    if (typeof yesterdayParam === 'number' && typeof last7DaysParam === 'number') {
      return percentage(calculatePercentage(yesterdayParam, last7DaysParam));
    } else {
      return '-';
    }
  };

  const yesterdayOverLast7DaysComparison = compareYesterdayOverLast7Days(yesterday, last7days);

  return (
    <>
      <td>{formatNumber(yesterday, wrapper)}</td>
      <td className="c-metric-trend__icon">
        <img
          src={getCorrectArrowIcon(yesterday, last7days)}
          className={cs('u-margin-left--auto', {
            'u-color--green u-transform--rotate-45deg': yesterdayOverLast7DaysComparison > 0,
            'u-transform--rotate-45deg': yesterdayOverLast7DaysComparison === 0,
            'u-color--red u-transform--rotate-135deg': yesterdayOverLast7DaysComparison < 0,
          })}
          alt="Icon showing the trend of metric"
        />
      </td>

      <td className="c-metric-trend__last-column">
        <TooltipWrapper
          message={translate('dashboard_yesterday_overview_widget_compared')}
          position={TooltipPosition.BOTTOM_TRAILING}
          noTinyPaddingTop={true}
          isAddingPadding={false}
        >
          <span
            className={cs({
              'u-color--green': yesterdayOverLast7DaysComparison > 0,
              'u-color--red': yesterdayOverLast7DaysComparison < 0,
            })}
          >
            {percentageYesterdayOverLast7Days(yesterday, last7days)}
          </span>
        </TooltipWrapper>
      </td>
    </>
  );
};
