import { Aggregation, View, VIEWS_BM_EDIT_BID_MODIFIER, fromTo, TotalTypes } from '../models';

export const VIEW_NAME: fromTo<View, string> = {
  [View.HOTEL]: 'hotel-view',
  [View.POS]: 'pos-view',
  [View.YESTERDAY_OVERVIEW_OLD]: 'yesterday_overview',
  [View.TOP_METRICS]: 'top_metrics',
  [View.INVESTMENT_RETURN]: 'investment_return',
  [View.RAS_REPORT]: 'ras-report',
  [View.AB_ACTIVE_ITEMS]: 'ab-active-items',
  [View.HISTORY]: 'logs',
  [View.TIME]: 'time-view',
  [View.TIME_DAY]: 'time-view',
  [View.TIME_WEEK]: 'time-view',
  [View.TIME_MONTH]: 'time-view',
  [View.HOTEL_DETAIL]: 'hotel-detail-view',
  [View.HOTEL_DETAIL_DAY]: 'hotel-detail-view',
  [View.HOTEL_DETAIL_WEEK]: 'hotel-detail-view',
  [View.HOTEL_DETAIL_MONTH]: 'hotel-detail-view',
  [View.RATING]: 'rating-dimension',
  [View.CITY]: 'city-dimension',
  [View.COUNTRY]: 'country-dimension',
  [View.REGION]: 'region-dimension',
  [View.STARS]: 'stars-dimension',
  [View.ACCOMMODATION_TYPE]: 'accommodation-type-dimension',
  [View.HOTEL_WITHOUT_IMPRESSIONS]: 'hotel-view-without-impressions',
  [View.HOTEL_MULTI_LOCALE]: 'hotel-view-multi-locale',
  [View.INVENTORY]: 'inventory',
  [View.BM_POS_LENGTH_OF_STAY]: 'los-view',
  [View.BM_POS_TIME_TO_TRAVEL]: 'ttt-view',
  [View.BM_POS_COMBINED_MODIFIERS]: 'combined-mods-view',
  [View.TIMELINE]: 'timeline-view',
  [View.TIMELINE_WITH_SL]: 'timeline-view-with-sl',
  [View.YESTERDAY_OVERVIEW]: 'yesterday-overview',
  [View.POS_OVERVIEW]: 'pos-overview',
  [View.BMLU]: 'mbl-u-view',
  [View.BIDDING_SUGGESTION_DOWNLOAD]: 'bidding-suggestions-download',
  [View.INVENTORY_MATCHED]: 'inventory-matched',
  [View.INVENTORY_UNMATCHED]: 'inventory-unmatched',
  [View.PROPERTY_STATIC_DETAILS]: 'property-view',
  [View.BM_POS_DEFAULT_DATE]: 'dd-view',
  [View.CAMPAIGNS]: 'campaigns',
  [View.TEB_RECOMMENDATIONS]: 'teb_recommendations',
  [View.HOTEL_WITHOUT_IMPRESSIONS_MULTI_LOCALE]: 'hotel-view-without-impressions-multi-locale',
  [View.SPONSORED_HOTEL]: 'sl-hotel-view',
  [View.SPONSORED_HOTEL_DETAIL]: 'sl-hotel-detail-view',
  [View.SPONSORED_HOTEL_DETAIL_DAY]: 'sl-hotel-detail-view',
  [View.SPONSORED_HOTEL_DETAIL_WEEK]: 'sl-hotel-detail-view',
  [View.SPONSORED_HOTEL_DETAIL_MONTH]: 'sl-hotel-detail-view',
  [View.SPONSORED_POS]: 'sl-pos-view',
  [View.SPONSORED_CAMPAIGNS]: 'sl-campaign-view',
  [View.BM_POS_GROUP_SIZE]: 'gs-view',
  [View.SPONSORED_TIME]: 'sl-time-view',
  [View.SPONSORED_TIME_DAY]: 'sl-time-view',
  [View.SPONSORED_TIME_WEEK]: 'sl-time-view',
  [View.SPONSORED_TIME_MONTH]: 'sl-time-view',
  [View.BIDDING_TYPE]: 'bidding-type-view',
  [View.BIDDING_TYPE_CB_WITH_SL]: 'bidding-type-view-cb-with-sl',
  [View.BM_CB_WITH_SL]: 'bm-view-cb-with-sl',
  [View.BRAND_CB_WITH_SL]: 'brand-view-cb-with-sl',
  [View.NAME_PARTNERREF_CB_WITH_SL]: 'name-partnerref-view-cb-with-sl',
  [View.POS_REGION_CB_WITH_SL]: 'pos-region-view-cb-with-sl',
  [View.POS_CB_WITH_SL]: 'pos-view-cb-with-sl',
  [View.PROPERTY_LOCATION_CB_WITH_SL]: 'property-location-view-cb-with-sl',
  [View.YESTERDAY_OVERVIEW_WITH_SL]: 'yesterday-overview-with-sl',
  [View.PROPERTY_GROUPS_WITH_SL]: 'property-group-view-cb-with-sl',
  [View.CAMPAIGN_PROPERTIES_CPC]: 'campaign-cpc-properties',
  [View.CAMPAIGN_PROPERTIES_CPA]: 'campaign-cpa-properties',
  [View.INACTIVE_PROPERTIES_CPC]: 'inactive-cpc-properties',
  [View.INVISIBLE_PROPERTIES_CPC]: 'invisible-cpc-properties',
  [View.CAMPAIGNS_NO_BID_DOWNLOAD]: 'campaigns-no-bid-items-download',
  [View.PROPERTY_GROUPS]: 'property-groups-view',
};

export const AGGREGATION_NAME: fromTo<Aggregation, string> = {
  [Aggregation.HOTEL]: 'hv_by_day_pos',
  [Aggregation.HOTEL_WITHOUT_IMPRESSIONS]: 'hv_without_impressions_by_day_pos',
  [Aggregation.HOTEL_DETAIL_DAY]: 'hdv_by_item_day',
  [Aggregation.HOTEL_DETAIL_WEEK]: 'hdv_by_item_week',
  [Aggregation.HOTEL_DETAIL_MONTH]: 'hdv_by_item_month',
  [Aggregation.POS]: 'by_pos',
  [Aggregation.TIME_DAY]: 'by_day',
  [Aggregation.TIME_WEEK]: 'by_week',
  [Aggregation.TIME_MONTH]: 'by_month',
  [Aggregation.TIME_QUARTER]: 'by_quarter',
  [Aggregation.TOTAL]: 'total',
  [Aggregation.RATING]: 'hv_by_rating',
  [Aggregation.CITY]: 'hv_by_city',
  [Aggregation.STARS]: 'hv_by_stars',
  [Aggregation.COUNTRY]: 'hv_by_country',
  [Aggregation.REGION]: 'hv_by_region',
  [Aggregation.ACCOMMODATION_TYPE]: 'hv_by_accommodation_type',
  [Aggregation.BM_POS_LENGTH_OF_STAY]: 'length-of-stay',
  [Aggregation.BM_POS_TIME_TO_TRAVEL]: 'time-to-travel',
  [Aggregation.BM_POS_COMBINED_MODIFIERS]: 'combined-modifiers',
  [Aggregation.BY_PERIOD]: 'by_period',
  [Aggregation.BY_PARTNER_REF]: 'by_partner_ref',
  [Aggregation.BM_POS_DEFAULT_DATE]: 'default-date',
  [Aggregation.CAMPAIGNS]: 'campaigns',
  [Aggregation.BM_POS_GROUP_SIZE]: 'group-size',
  [Aggregation.SPONSORED_HOTEL_DETAIL_DAY]: 'sl_hdv_by_item_day',
  [Aggregation.SPONSORED_HOTEL_DETAIL_WEEK]: 'sl_hdv_by_item_week',
  [Aggregation.SPONSORED_HOTEL_DETAIL_MONTH]: 'sl_hdv_by_item_month',
  [Aggregation.BIDDING_TYPE]: 'by_cpa_group',
  [Aggregation.BRAND]: 'by_brand',
  [Aggregation.CHART_GROUP_SIZE]: 'group-size2',
  [Aggregation.CHART_STARS]: 'by_stars',
  [Aggregation.CHART_ACCOMMODATION_TYPE]: 'by_accommodation_type',
  [Aggregation.CHART_COUNTRY]: 'by_country2',
  [Aggregation.CHART_REGION]: 'by_region2',
  [Aggregation.CHART_CITY]: 'by_city2',
  [Aggregation.CHART_POS_REGION]: 'by_pos_region',
  [Aggregation.BY_TIMELINE_DAY]: 'by_timeline_day',
  [Aggregation.BY_TIMELINE_WEEK]: 'by_timeline_week',
  [Aggregation.BY_TIMELINE_MONTH]: 'by_timeline_month',
  [Aggregation.BY_TIMELINE_QUARTER]: 'by_timeline_quarter',
  [Aggregation.BY_ITEM_ID]: 'by_item_id',
  [Aggregation.BY_COUNTRY]: 'by_country',
  [Aggregation.BY_REGION]: 'by_region',
  [Aggregation.BY_CITY]: 'by_city',
  [Aggregation.PROPERTY_GROUPS]: 'by_property_group2',
  [Aggregation.BY_BIDDING_TYPE]: 'by_bidding_type',
  [Aggregation.TIME_PERIOD]: 'timePeriod',
};

export const VIEW_TITLE_KEY: fromTo<View, string> = {
  [View.HOTEL]: '',
  [View.POS]: '',
  [View.YESTERDAY_OVERVIEW_OLD]: '',
  [View.TOP_METRICS]: '',
  [View.INVESTMENT_RETURN]: '',
  [View.RAS_REPORT]: '',
  [View.AB_ACTIVE_ITEMS]: '',
  [View.HISTORY]: '',
  [View.TIME]: '',
  [View.TIME_DAY]: '',
  [View.TIME_WEEK]: '',
  [View.TIME_MONTH]: '',
  [View.HOTEL_DETAIL]: '',
  [View.HOTEL_DETAIL_DAY]: '',
  [View.HOTEL_DETAIL_WEEK]: '',
  [View.HOTEL_DETAIL_MONTH]: '',
  [View.RATING]: '',
  [View.CITY]: '',
  [View.COUNTRY]: '',
  [View.REGION]: '',
  [View.STARS]: '',
  [View.ACCOMMODATION_TYPE]: '',
  [View.HOTEL_WITHOUT_IMPRESSIONS]: '',
  [View.HOTEL_MULTI_LOCALE]: '',
  [View.INVENTORY]: '',
  [View.BM_POS_LENGTH_OF_STAY]: 'analytics_home_bm_pos_length_of_stay',
  [View.BM_POS_TIME_TO_TRAVEL]: 'analytics_home_bm_pos_time_to_travel',
  [View.BM_POS_COMBINED_MODIFIERS]: 'analytics_home_bm_pos_combined_modifiers',
  [View.TIMELINE]: '',
  [View.TIMELINE_WITH_SL]: '',
  [View.BIDDING_TYPE_CB_WITH_SL]: '',
  [View.BM_CB_WITH_SL]: '',
  [View.BRAND_CB_WITH_SL]: '',
  [View.NAME_PARTNERREF_CB_WITH_SL]: '',
  [View.POS_REGION_CB_WITH_SL]: '',
  [View.POS_CB_WITH_SL]: '',
  [View.PROPERTY_LOCATION_CB_WITH_SL]: '',
  [View.YESTERDAY_OVERVIEW_WITH_SL]: '',
  [View.PROPERTY_GROUPS_WITH_SL]: '',
  [View.YESTERDAY_OVERVIEW]: '',
  [View.POS_OVERVIEW]: '',
  [View.BMLU]: '',
  [View.BIDDING_SUGGESTION_DOWNLOAD]: '',
  [View.INVENTORY_MATCHED]: '',
  [View.INVENTORY_UNMATCHED]: '',
  [View.PROPERTY_STATIC_DETAILS]: '',
  [View.BM_POS_DEFAULT_DATE]: 'analytics_home_bm_pos_default_date',
  [View.CAMPAIGNS]: '',
  [View.TEB_RECOMMENDATIONS]: '',
  [View.HOTEL_WITHOUT_IMPRESSIONS_MULTI_LOCALE]: '',
  [View.SPONSORED_HOTEL]: '',
  [View.SPONSORED_HOTEL_DETAIL]: '',
  [View.SPONSORED_HOTEL_DETAIL_DAY]: '',
  [View.SPONSORED_HOTEL_DETAIL_WEEK]: '',
  [View.SPONSORED_HOTEL_DETAIL_MONTH]: '',
  [View.SPONSORED_POS]: '',
  [View.SPONSORED_CAMPAIGNS]: '',
  [View.BM_POS_GROUP_SIZE]: '',
  [View.SPONSORED_TIME]: '',
  [View.SPONSORED_TIME_DAY]: '',
  [View.SPONSORED_TIME_WEEK]: '',
  [View.SPONSORED_TIME_MONTH]: '',
  [View.BIDDING_TYPE]: '',
  [View.CAMPAIGN_PROPERTIES_CPC]: '',
  [View.CAMPAIGN_PROPERTIES_CPA]: '',
  [View.INACTIVE_PROPERTIES_CPC]: '',
  [View.INVISIBLE_PROPERTIES_CPC]: '',
  [View.CAMPAIGNS_NO_BID_DOWNLOAD]: '',
  [View.PROPERTY_GROUPS]: '',
};

export const BM_VIEW_DIMENSION: fromTo<
  VIEWS_BM_EDIT_BID_MODIFIER | View.BM_POS_COMBINED_MODIFIERS,
  string
> = {
  [View.BM_POS_LENGTH_OF_STAY]: 'LOS',
  [View.BM_POS_TIME_TO_TRAVEL]: 'TTT',
  [View.BM_POS_DEFAULT_DATE]: 'DD',
  [View.BM_POS_GROUP_SIZE]: 'GS',
  [View.BM_POS_COMBINED_MODIFIERS]: '',
};

export const VIEW_TOTAL_TYPE: fromTo<View, TotalTypes> = {
  [View.HOTEL]: TotalTypes.TOTAL,
  [View.POS]: TotalTypes.TOTAL,
  [View.YESTERDAY_OVERVIEW_OLD]: TotalTypes.TOTAL,
  [View.TOP_METRICS]: TotalTypes.TOTAL,
  [View.INVESTMENT_RETURN]: TotalTypes.TOTAL,
  [View.RAS_REPORT]: TotalTypes.TOTAL,
  [View.AB_ACTIVE_ITEMS]: TotalTypes.TOTAL,
  [View.HISTORY]: TotalTypes.TOTAL,
  [View.TIME]: TotalTypes.TOTAL,
  [View.TIME_DAY]: TotalTypes.TOTAL,
  [View.TIME_WEEK]: TotalTypes.TOTAL,
  [View.TIME_MONTH]: TotalTypes.TOTAL,
  [View.HOTEL_DETAIL]: TotalTypes.TOTAL_WITH_FILTERS,
  [View.HOTEL_DETAIL_DAY]: TotalTypes.TOTAL_WITH_FILTERS,
  [View.HOTEL_DETAIL_WEEK]: TotalTypes.TOTAL_WITH_FILTERS,
  [View.HOTEL_DETAIL_MONTH]: TotalTypes.TOTAL_WITH_FILTERS,
  [View.RATING]: TotalTypes.TOTAL,
  [View.CITY]: TotalTypes.TOTAL,
  [View.COUNTRY]: TotalTypes.TOTAL,
  [View.REGION]: TotalTypes.TOTAL,
  [View.STARS]: TotalTypes.TOTAL,
  [View.ACCOMMODATION_TYPE]: TotalTypes.TOTAL,
  [View.HOTEL_WITHOUT_IMPRESSIONS]: TotalTypes.TOTAL,
  [View.HOTEL_MULTI_LOCALE]: TotalTypes.TOTAL,
  [View.INVENTORY]: TotalTypes.TOTAL,
  [View.BM_POS_LENGTH_OF_STAY]: TotalTypes.TOTAL,
  [View.BM_POS_TIME_TO_TRAVEL]: TotalTypes.TOTAL,
  [View.BM_POS_COMBINED_MODIFIERS]: TotalTypes.TOTAL,
  [View.TIMELINE]: TotalTypes.TOTAL,
  [View.TIMELINE_WITH_SL]: TotalTypes.TOTAL,
  [View.YESTERDAY_OVERVIEW]: TotalTypes.TOTAL,
  [View.POS_OVERVIEW]: TotalTypes.TOTAL,
  [View.BMLU]: TotalTypes.TOTAL,
  [View.BIDDING_SUGGESTION_DOWNLOAD]: TotalTypes.TOTAL,
  [View.INVENTORY_MATCHED]: TotalTypes.TOTAL,
  [View.INVENTORY_UNMATCHED]: TotalTypes.TOTAL,
  [View.PROPERTY_STATIC_DETAILS]: TotalTypes.TOTAL,
  [View.BM_POS_DEFAULT_DATE]: TotalTypes.TOTAL,
  [View.CAMPAIGNS]: TotalTypes.TOTAL,
  [View.TEB_RECOMMENDATIONS]: TotalTypes.TOTAL,
  [View.HOTEL_WITHOUT_IMPRESSIONS_MULTI_LOCALE]: TotalTypes.TOTAL,
  [View.SPONSORED_HOTEL]: TotalTypes.TOTAL,
  [View.SPONSORED_HOTEL_DETAIL]: TotalTypes.TOTAL_WITH_FILTERS,
  [View.SPONSORED_HOTEL_DETAIL_DAY]: TotalTypes.TOTAL_WITH_FILTERS,
  [View.SPONSORED_HOTEL_DETAIL_WEEK]: TotalTypes.TOTAL_WITH_FILTERS,
  [View.SPONSORED_HOTEL_DETAIL_MONTH]: TotalTypes.TOTAL_WITH_FILTERS,
  [View.SPONSORED_POS]: TotalTypes.TOTAL,
  [View.SPONSORED_CAMPAIGNS]: TotalTypes.TOTAL,
  [View.BM_POS_GROUP_SIZE]: TotalTypes.TOTAL,
  [View.SPONSORED_TIME]: TotalTypes.TOTAL,
  [View.SPONSORED_TIME_DAY]: TotalTypes.TOTAL,
  [View.SPONSORED_TIME_WEEK]: TotalTypes.TOTAL,
  [View.SPONSORED_TIME_MONTH]: TotalTypes.TOTAL,
  [View.BIDDING_TYPE]: TotalTypes.TOTAL,
  [View.BIDDING_TYPE_CB_WITH_SL]: TotalTypes.TOTAL,
  [View.BM_CB_WITH_SL]: TotalTypes.TOTAL,
  [View.BRAND_CB_WITH_SL]: TotalTypes.TOTAL,
  [View.NAME_PARTNERREF_CB_WITH_SL]: TotalTypes.TOTAL,
  [View.POS_REGION_CB_WITH_SL]: TotalTypes.TOTAL,
  [View.POS_CB_WITH_SL]: TotalTypes.TOTAL,
  [View.PROPERTY_LOCATION_CB_WITH_SL]: TotalTypes.TOTAL,
  [View.YESTERDAY_OVERVIEW_WITH_SL]: TotalTypes.TOTAL,
  [View.PROPERTY_GROUPS_WITH_SL]: TotalTypes.TOTAL,
  [View.CAMPAIGN_PROPERTIES_CPC]: TotalTypes.TOTAL,
  [View.CAMPAIGN_PROPERTIES_CPA]: TotalTypes.TOTAL,
  [View.INACTIVE_PROPERTIES_CPC]: TotalTypes.TOTAL,
  [View.INVISIBLE_PROPERTIES_CPC]: TotalTypes.TOTAL,
  [View.CAMPAIGNS_NO_BID_DOWNLOAD]: TotalTypes.TOTAL,
  [View.PROPERTY_GROUPS]: TotalTypes.TOTAL,
};
