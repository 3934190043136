import { Aggregation, DateRange, FilterColumn, Row, View } from '../../models';
import ApiAnalyticsHelper from '../../helpers/api/analytics/api-analytics-helper';
import { ReactText, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../global/context/app-context';
import { getPartnersId } from '../../services/app-service';
import {
  useCompareDateRangePeriods,
  useIsComparing,
} from '../../components/date-picker/date-picker.state';
import { sortBy } from 'lodash';
import { BaseViewPayloadV2 } from '../../helpers/api/analytics/types';
import { showNotification } from '../../services/notification-service';
import { NotificationLevel, DATE_FORMAT_ISO_8601, COLUMN_KEY_CPA_GROUP } from '../../constants';
import { translate, formatDateRange } from '../../helpers/utils';
import { useAggregations } from '../use-aggregations/use-aggregations';

type Props = {
  view: View;
  aggregation: Aggregation;
  payload: BaseViewPayloadV2;
  filters: FilterColumn[];
  parentRows: Row[];
  columnId: string;
};

const filtersToExclude = ['is_performance_data', COLUMN_KEY_CPA_GROUP];

export const useNestedRows = ({
  view,
  aggregation,
  payload,
  filters,
  parentRows,
  columnId,
}: Props) => {
  const { partner, subPartners } = useContext(AppContext);
  const [isComparing] = useIsComparing();
  const [compareDatePeriods] = useCompareDateRangePeriods();
  const aggregations = useAggregations(aggregation);
  const [nestedRows, setNestedRows] = useState<Row[]>(parentRows);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSubRows = (sortedDatePeriods: DateRange[], rowIds: ReactText[]) => {
    const validRowIds = rowIds.filter(el => !!el);
    const searchByParentRowIds: FilterColumn | null =
      validRowIds.length > 0
        ? {
            columnName: columnId,
            columnLabel: '',
            type: 'IN',
            value: validRowIds.join(','),
          }
        : null;

    const subRowsFilters = filters.filter(filterObj =>
      filtersToExclude.includes(filterObj.columnName),
    );

    return Promise.all(
      sortedDatePeriods.map(({ from, to }) =>
        ApiAnalyticsHelper.fetchRowsByView(view, aggregations, {
          ...payload,
          from,
          to,
          pagination: { numPage: 1, numItemsPerPage: rowIds.length },
          partnersId: getPartnersId(partner, subPartners),
          filters: subRowsFilters.concat(searchByParentRowIds ? [searchByParentRowIds] : []),
        }),
      ),
    );
  };

  const buildNestedRows = (subRows: Row[][], sortedDatePeriods: DateRange[]) => {
    const columnsToMatch = [columnId];

    const result = parentRows.map(parentRow => ({
      ...parentRow,
      nested: subRows.map((rows, index) => ({
        ...findAndRemoveMatchingRow(rows, parentRow, columnsToMatch),
        rowHeader: formatDateRange(sortedDatePeriods[index], DATE_FORMAT_ISO_8601),
      })),
    }));
    return result;
  };

  const fetchNestedRows = async (): Promise<Row[]> => {
    const rowIds = parentRows.map(row => row[columnId] as ReactText);
    if (rowIds.length === 0) return Promise.resolve(parentRows);

    const datePeriods = [{ from: payload.from, to: payload.to }, ...compareDatePeriods];
    const sortedDatePeriods = sortBy(datePeriods, ['from', 'to']).reverse();
    setIsLoading(true);
    try {
      const subRows = await fetchSubRows(sortedDatePeriods, rowIds);

      return buildNestedRows(subRows, sortedDatePeriods);
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    } finally {
      setIsLoading(false);
    }
    return Promise.resolve(parentRows);
  };

  useEffect(() => {
    if (isComparing) fetchNestedRows().then(setNestedRows);
    else {
      setNestedRows(parentRows.map(row => ({ ...row, nestedRows: [] })));
      setIsLoading(false);
    }
  }, [isComparing, parentRows]);

  return {
    nestedRows,
    isLoading,
  };
};

const findAndRemoveMatchingRow = (rows: Row[], totalRow: Row, columnsToMatch: string[]) => {
  const index = rows.findIndex(row => areRowsMatching(row, totalRow, columnsToMatch));
  if (index === -1) return;
  return rows.splice(index, 1)[0];
};

const areRowsMatching = (row: Row, totalRow: Row, columnsToMatch: string[]) =>
  columnsToMatch.every(columnId => row[columnId] === totalRow[columnId]);
